import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LocationListGroup from "../components/locations/location-list-group";
import Seo from "../components/seo";
import Logo from "../images/onpoint/onpoint-400x160.png";
import Photo from "../images/onpoint/rob-stuart.jpg";
import HowToDonateSection from "../sections/how-to-donate-section";
import QuoteSection from "../sections/quote-section";
import AboutSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Toy Drops - Donation Sites" mdxType="Seo" />
  <LocationListGroup lists={[{
        title: false,
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/gtd-partners.json",
        id: "donation-sites"
      }, {
        title: "iQ Credit Union",
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/iq-locations.json",
        id: "iq-locations"
      }, {
        title: "Fred Meyer Stores",
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/fred-meyer-locations.json",
        id: "fred-meyer-locations"
      }, {
        title: "Toyota Locations",
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/toyota-locations.json",
        id: "toyota-locations"
      }]} headline="Our Community Partners" map={false} mdxType="LocationListGroup" />
  <HowToDonateSection mdxType="HowToDonateSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      